
import dayjs from 'dayjs'
import {MODULE_OBJ} from '@/config/constEnum'
import {defineComponent, onMounted, ref} from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {ElMessage} from 'element-plus'

export default defineComponent({
  name: 'Module',
  setup() {
    const pageSize = 10
    const total = ref(0)
    const tableData = ref([])
    const moduleList = ref<{ id: Number; name: String }[]>([])
    const page = ref(1)
    const title = ref('')
    const signId = ref('')
    const content = ref('')
    const applyReason = ref('')
    const rejectDialogVisible = ref(false)
    const rejectReason = ref('')
    const rejectId = ref(0)
    const dialogVisible = ref(false)
    const checkStatus = ref(0)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }
    const seeDialogVisible = ref(false)
    const editMethodDialogVisible = ref(false)

    const newChannel = ref('')
    const newBackupChannel = ref('')
    const editChanelId = ref(0)
    const channelMap = ref({})

    setTimeout(async () => {
      const resp = await axios.get("/-/admin_sms/channel/list")
      channelMap.value = resp.data.msg
    }, 16)

    const onChangePage = (val: number) => {
      page.value = val
      getList()
    }

    const formatTime = (val: string) => {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }

    const getList = () => {
      const reqData = {
        status: 'all',
        size: pageSize,
        page: page.value
      }
      axios.get('/-/admin_sms/template/list', {params: reqData}).then(res => {
        const data = res.data.msg
        total.value = data.total
        tableData.value = data.template
      })
    }

    const clearReq = () => {
      title.value = ''
      signId.value = ''
      content.value = ''
      applyReason.value = ''
    }

    const submit = () => {
      const reqData = {
        title: title.value,
        type: 'captcha',
        sign_id: signId.value,
        content: content.value,
        apply_reason: applyReason.value
      }

      if (!title.value) return ElMessage.warning('请输入主题')
      if (!signId.value) return ElMessage.warning('请选择模板')
      if (!content.value) return ElMessage.warning('请填写主题内容')
      if (!applyReason.value) return ElMessage.warning('请填写申请原因')

      axios.post('/-/user/template/new', reqData).then(() => {
        ElMessage.success('新增模板成功')
        clearReq()
        getList()
        dialogVisible.value = false
      })
    }

    const audit = (val: number, isAccess: boolean) => {
      if (isAccess) {
        axios
            .post('/-/admin_sms/template/approve', {id: val})
            .then(() => {
              ElMessage.success('通过成功')
              getList()
            })
            .catch(e => {
              console.warn(e)
            })
      } else {
        rejectId.value = val
        rejectDialogVisible.value = true
      }
    }

    const editMethod = (val: number) => {
      editMethodDialogVisible.value = true
      editChanelId.value = val
    }

    const reject = () => {
      axios
          .post('/-/admin_sms/template/reject', {id: rejectId.value, reject_reason: rejectReason.value})
          .then(() => {
            ElMessage.success('拒绝成功')
            rejectReason.value = ''
            rejectDialogVisible.value = false
            getList()
          })
          .catch(e => {
            console.warn(e)
          })
    }

    //获取模版列表
    const getModuleList = () => {
      axios.get('/-/user/sign/all').then(res => {
        const data = res.data.msg
        moduleList.value = _.map(data.signs, item => {
          return {
            name: item.Name,
            id: item.ID
          }
        })
      })
    }

    const submitMethod = () => {
      const reqData = {
        new_channel: newChannel.value,
        new_backup_channel: newBackupChannel.value,
        id: editChanelId.value
      }
      axios.post('/-/admin_sms/template/channel', reqData).then(() => {
        ElMessage.success('修改成功')
        editMethodDialogVisible.value = false
        getList()
      }).catch(e => {
        ElMessage.error(`${e}`)
      })
    }
    onMounted(() => {
      getList()
      getModuleList()
    })
    return {
      page,
      audit,
      total,
      title,
      reject,
      signId,
      submit,
      content,
      pageSize,
      tableData,
      newChannel,
      newBackupChannel,
      editMethod,
      moduleList,
      formatTime,
      applyReason,
      handleClose,
      checkStatus,
      rejectReason,
      onChangePage,
      editChanelId,
      submitMethod,
      dialogVisible,
      seeDialogVisible,
      rejectDialogVisible,
      moduleObj: MODULE_OBJ,
      chanelObj: channelMap,
      editMethodDialogVisible
    }
  }
})
